$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

// On page load, populate burden equation values for selected stat, usually its AL
// $(window).on("load", populateState);
$(window).on("load", populateTotalChargedBurden);

$(window).on("load", function () {
    let selected_state = $( "#selected-state option:selected" ).text()
    if (selected_state == 'CA') {
        $("#caett-tr").show()
    }
    else {
        $("#caett-tr").hide()
    }
});

$(document).ready(function () {

    $( "#selected-state" ).on('change', function () {
        let selected_state = $( "#selected-state option:selected" ).text()
        if (selected_state == 'CA') {
            $("#caett-tr").show()
        }
        else {
            $("#caett-tr").hide()
        }
    });



    $(':input[type="number"]').on('input', function (event) {
        var c = this.selectionStart,
            r = /[^a-z0-9.]/gi,
            v = $(this).val();
        if(r.test(v)) {
            $(this).val(v.replace(r, ''));
            c--;
        }
        // this.setSelectionRange(c, c);
    });
    $('.percentage_input').bind('keyup mouseup', function (event) {
        if ($(this).val() > 100){
            alert("Percentages can't exceed 100%");
            $(this).val('100.00');
        }
    });
    $('.percentage_input_tcb').bind('keyup mouseup', function (event) {
        let caett = $("#state-caett").val() > 0 ? $("#state-caett").val() : 0;       // caett
        let suta = $("#state-suta").val() > 0 ? $("#state-suta").val() : 0;
        let futa = $("#state-futa").val() > 0 ? $("#state-futa").val() : 0;
        let fica = $("#state-fica").val() > 0 ? $("#state-fica").val() : 0;
        let med = $("#state-med").val() > 0 ? $("#state-med").val() : 0;
        let caf = $("#state-caf").val() > 0 ? $("#state-caf").val() : 0;
        let wsc = $("#state-wsc").val() > 0 ? $("#state-wsc").val() : 0;
        let gl = $("#state-gl").val() > 0 ? $("#state-gl").val() : 0;
        let misc = $("#state-miscellaneous").val() > 0 ? $("#state-miscellaneous").val() : 0;
        let total_burden = parseFloat(suta) + parseFloat(futa) +        // caett
            parseFloat(fica) + parseFloat(med) + parseFloat(caf) +
            parseFloat(wsc) + parseFloat(gl) + parseFloat(misc);

        let selected_state = $( "#selected-state option:selected" ).text()
        if (selected_state == 'CA') {
            total_burden = parseFloat(total_burden) + parseFloat(caett);
        }

        if (parseFloat(total_burden) > 100.00){
            alert("Total charged burden should not exceed 100%");
            $(this).val(0);
        }
    });

    $('.percentage_input_tcb, .percentage_input').on('change', function () {
        if (parseFloat($(this).val()) > 0) {
            $(this).val(parseFloat(this.value).toFixed(2)).attr('value', parseFloat(this.value).toFixed(2));
        }
    });

    $('.integer-inputs').on('change', function () {
        if (this.value == '') {
            $(this).val(0)
        }
        else {
            $(this).val(parseInt(this.value))
        }
    });

    $('.decimal-input, .percentage_input').on('change', function () {
        if (this.value == '') {
            $(this).val(parseFloat('0.00').toFixed(2))
        }
        else {
            $(this).val(parseFloat(this.value).toFixed(2))
        }
    });

    $('#estimated-headcount').bind('keyup mouseup', function () {
        if (this.value == '' || parseInt(this.value) == 0) {
            $(this).val(parseInt('0'))
            $("#background-checks-val").val(parseFloat('0').toFixed(2))
            $("#drug-test-val").val(parseFloat('0').toFixed(2))
        }
        else {
            $(this).val(parseInt(this.value))
            $("#background-checks-val").val(parseFloat($("#background-checks-val").val()).toFixed(2))
            $("#drug-test-val").val(parseFloat($("#drug-test-val").val()).toFixed(2))
        }
    });

    $('#selected-client-title').on('change', function () {
        if ($('#client_name_ajax').length) {
            $('#client_name_ajax').val(this.value);
        }
    });

    $("#selected-vms-msp").change(function () {
        if ($("#selected-vms-msp").val() == 0) {
            $("#selected-applicable-fee").prop("disabled", true)
        } else {
            $("#selected-applicable-fee").prop("disabled", false)
        }
    });

    $("#background-checks").change(function () {
        if ($("#background-checks").val() == '0') {
            $("#background-checks-val").val('0.00')
            $("#background-checks-val").prop("disabled", true)
        } else {
            $("#background-checks-val").val(parseFloat(parseFloat($('#estimated-headcount').val()) * 25).toFixed(2))
            $("#background-checks-val").prop("disabled", false)
        }
    });

    $("#drug-tests").change(function () {
        if ($("#drug-tests").val() == '0') {
            $("#drug-test-val").val('0.00')
            $("#drug-test-val").prop("disabled", true)
        } else {
            $("#drug-test-val").val(parseFloat(parseFloat($('#estimated-headcount').val()) * 28).toFixed(2))
            $("#drug-test-val").prop("disabled", false)
        }
    });

    $("#background-checks-val").on('change', function (event) {
        if (this.value == '') {
            $(this).val('0.00')
        }
        else {
            $(this).val(parseFloat(this.value).toFixed(2))
        }
    })

    $("#drug-test-val").on('change', function (event) {
        if (this.value == '') {
            $(this).val('0.00')
        }
        else {
            $(this).val(parseFloat(this.value).toFixed(2))
        }
    })

    $('#estimated-headcount').bind('keyup mouseup', function (event) {
        if ($("#background-checks").val() == '1') {
            $("#background-checks-val").val(parseFloat(parseFloat(this.value) * 25).toFixed(2))
        }
        if ($("#drug-tests").val() == '1') {
            $("#drug-test-val").val(parseFloat(parseFloat(this.value) * 28).toFixed(2))
        }
        if (this.value == '') {
            $(this).val(0)
        }
        else {
            $(this).val(parseInt(this.value))
        }
    });

    // Populate state burden factors data on changing the state
    // $("select#selected-state").change(populateState);
    $("select#selected-state").change(populateTotalChargedBurden);

    function calTotalChargedBurden() {
        let caett = $("#state-caett").val();        // caett
        if (caett == '') {
            $("#caett").val(0)
            caett = 0;
        }
        let suta = $("#state-suta").val();
        if (suta == '') {
            $("#suta").val(0)
            suta = 0;
        }
        let futa = $("#state-futa").val();
        if (futa == '') {
            $("#futa").val(0)
            futa = 0;
        }
        let fica = $("#state-fica").val();
        if (fica == '') {
            $("#fica").val(0)
            fica = 0;
        }
        let med = $("#state-med").val();
        if (med == '') {
            $("#med").val(0)
            med = 0;
        }
        let caf = $("#state-caf").val();
        if (caf == '') {
            $("#caf").val(0)
            caf = 0;
        }
        let wsc = $("#state-wsc").val();
        if (wsc == '') {
            $("#wsc").val(0)
            wsc = 0;
        }
        let gl = $("#state-gl").val();
        if (gl == '') {
            $("#gl").val(0)
            gl = 0;
        }
        let misc = $("#state-miscellaneous").val();
        if (misc == '') {
            $("#misc").val(0)
            misc = 0;
        }

        let total_burden = parseFloat(suta) + parseFloat(futa) +        // caett
            parseFloat(fica) + parseFloat(med) + parseFloat(caf) +
            parseFloat(wsc) + parseFloat(gl) + parseFloat(misc);

        let selected_state = $( "#selected-state option:selected" ).text()
        if (selected_state == 'CA') {
            total_burden = parseFloat(total_burden) + parseFloat(caett);
        }

        $("#caett").attr('value', parseFloat(caett).toFixed(2)).val(parseFloat(caett).toFixed(2));        // caett
        $("#suta").attr('value', parseFloat(suta).toFixed(2)).val(parseFloat(suta).toFixed(2));
        $("#futa").attr('value', parseFloat(futa).toFixed(2)).val(parseFloat(futa).toFixed(2));
        $("#fica").attr('value', parseFloat(fica).toFixed(2)).val(parseFloat(fica).toFixed(2));
        $("#med").attr('value', parseFloat(med).toFixed(2)).val(parseFloat(med).toFixed(2));

        $("#caf").attr('value', parseFloat(caf).toFixed(2)).val(parseFloat(caf).toFixed(2));
        $("#wsc").attr('value', parseFloat(wsc).toFixed(2)).val(parseFloat(wsc).toFixed(2));
        $("#gl").attr('value', parseFloat(gl).toFixed(2)).val(parseFloat(gl).toFixed(2));
        $("#misc").attr('value', parseFloat(misc).toFixed(2)).val(parseFloat(misc).toFixed(2));
        $("#total-burden").attr('value', parseFloat(total_burden).toFixed(2)).val(parseFloat(total_burden).toFixed(2));
        $("#state-total-burden").attr('value', parseFloat(total_burden).toFixed(2)).val(parseFloat(total_burden).toFixed(2));
    }

    $("#state-caett, #state-suta, #state-fica, #state-futa, #state-med, #state-caf, #state-wsc, #state-gl, #state-miscellaneous").bind(      // caett
        "keyup mouseup", calTotalChargedBurden
    );
});

$(document).ready(function () {

    $('.percentage_input_tcb, .percentage_input').inputmask({
        alias: 'decimal',
        allowMinus: false,
        digits: 2,
        min: 0.00,
        max: 100.00,
        showMaskOnHover: false,
        showMaskOnFocus: false,
    });
    $('.decimal-input').inputmask({
        alias: 'decimal',
        allowMinus: false,
        digits: 2,
        min: 0.00,
        max: 999999999.99,
        showMaskOnHover: false,
        showMaskOnFocus: false,
    });
    $('.integer-inputs').inputmask({
        alias: 'decimal',
        allowMinus: false,
        digits: 0,
        numericInput: true,
        integerDigits: 9,
        max: 999999999.99,
        showMaskOnHover: false,
        showMaskOnFocus: false,
    });
})

// Function to populate values in tables/ inputs on page load and state change
function populateTotalChargedBurden() {
    var caett = parseFloat($("#state-caett").attr('value')).toFixed(2);        // caett
    var suta = parseFloat($("#state-suta").attr('value')).toFixed(2);
    var futa = parseFloat($("#state-futa").attr('value')).toFixed(2);
    var fica = parseFloat($("#state-fica").attr('value')).toFixed(2);
    var med = parseFloat($("#state-med").attr('value')).toFixed(2);
    var caf = parseFloat($("#state-caf").attr('value')).toFixed(2);
    var wsc = parseFloat($("#state-wsc").attr('value')).toFixed(2);
    var gl = parseFloat($("#state-gl").attr('value')).toFixed(2);
    var misc = parseFloat($("#state-miscellaneous").attr('value')).toFixed(2);

    let selected_state = $( "#selected-state option:selected" ).text()
    if (selected_state == 'CA') {
        $("#state-caett").attr('value', parseFloat(caett).toFixed(2)).val(parseFloat(caett).toFixed(2));
    }
    // $("#state-suta").attr('value', parseFloat(suta).toFixed(2)).val(parseFloat(suta).toFixed(2));
    $("#state-futa").attr('value', parseFloat(futa).toFixed(2)).val(parseFloat(futa).toFixed(2));
    $("#state-fica").attr('value', parseFloat(fica).toFixed(2)).val(parseFloat(fica).toFixed(2));
    $("#state-med").attr('value', parseFloat(med).toFixed(2)).val(parseFloat(med).toFixed(2));
    // $("#state-caf").attr('value', parseFloat(caf).toFixed(2)).val(parseFloat(caf).toFixed(2));
    // $("#state-wsc").attr('value', parseFloat(wsc).toFixed(2)).val(parseFloat(wsc).toFixed(2));
    // $("#state-gl").attr('value', parseFloat(gl).toFixed(2)).val(parseFloat(gl).toFixed(2));
    // $("#state-miscellaneous").attr('value', parseFloat(misc).toFixed(2)).val(parseFloat(misc).toFixed(2));

    if (selected_state == 'CA') {
        $("#caett").attr('value', parseFloat(caett).toFixed(2)).val(parseFloat(caett).toFixed(2));
    }
    // $("#suta").attr('value', parseFloat(suta).toFixed(2)).val(parseFloat(suta).toFixed(2));
    $("#futa").attr('value', parseFloat(futa).toFixed(2)).val(parseFloat(futa).toFixed(2));
    $("#fica").attr('value', parseFloat(fica).toFixed(2)).val(parseFloat(fica).toFixed(2));
    $("#med").attr('value', parseFloat(med).toFixed(2)).val(parseFloat(med).toFixed(2));
    // $("#caf").attr('value', parseFloat(caf).toFixed(2)).val(parseFloat(caf).toFixed(2));
    // $("#wsc").attr('value', parseFloat(wsc).toFixed(2)).val(parseFloat(wsc).toFixed(2));
    // $("#gl").attr('value', parseFloat(gl).toFixed(2)).val(parseFloat(gl).toFixed(2));
    // $("#miscellaneous").attr('value', parseFloat(misc).toFixed(2)).val(parseFloat(misc).toFixed(2));
    let total_burden = parseFloat(suta) + parseFloat(futa) + parseFloat(fica) + parseFloat(med) + parseFloat(caf)
        + parseFloat(wsc) + parseFloat(gl) + parseFloat(misc);

    if (selected_state == 'CA') {
        total_burden = parseFloat(total_burden) + parseFloat(caett);
    }

    $("#total-burden").attr('value', parseFloat(total_burden).toFixed(2)).val(parseFloat(total_burden).toFixed(2));
    $("#state-total-burden").attr('value', parseFloat(total_burden).toFixed(2)).val(parseFloat(total_burden).toFixed(2));
}

function populateState() {

    // Hide and show CAETT
    let selected_state = $( "#selected-state option:selected" ).text()
    if (selected_state == 'CA') {
        $("#caett-tr").show()
    }
    else {
        $("#caett-tr").hide()
    }

    if (!$("#pdf-btn").hasClass('d-none'))
        $("#pdf-btn").addClass('d-none')
    $("#state").val($("#selected-state").val())
    $("#wc-code").val($("#selected-wc-code").val())
    $("#markup-rate").val($("#selected-markup-rate").val())
    $("#commission-rate").val($("#selected-commission-rate").val())
    $("#vms-msp").val($("#selected-vms-msp").val())
    $("#applicable-fee").val($("#selected-applicable-fee").val())
    $("#selected-state").prop("disabled", true)
    $("#btn-calculate").prop("disabled", true)

    $("#state-caett, #state-suta, #state-futa, #state-fica, #state-med, #state-caf, #state-wsc, #state-gl, #state-miscellaneous")        // caett
        .prop("disabled", true);

    let selectedState = $("#selected-state option:selected").val();
    jQuery.ajax({
        type: "POST",
        url: "/state-burden-factors",
        headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
        data: {state: selectedState},
        success: function (data) {
            $("#selected-state").prop("disabled", false)
            $("#btn-calculate").prop("disabled", false)
            var caett = $("#state-caett").val();        // caett
            var suta = parseFloat(data.stateBurdenFactors.sui).toFixed(2);
            var futa = parseFloat(data.stateBurdenFactors.futa).toFixed(2);
            var fica = parseFloat(data.stateBurdenFactors.ss).toFixed(2);
            var med = parseFloat(data.stateBurdenFactors.med).toFixed(2);
            var caf = parseFloat(data.stateBurdenFactors.caf).toFixed(2);
            var wsc = parseFloat(data.stateBurdenFactors.wsc).toFixed(2);
            var gl = parseFloat(data.stateBurdenFactors.gl).toFixed(2);
            var misc = parseFloat(data.stateBurdenFactors.misc).toFixed(2);
            let selected_state = $( "#selected-state option:selected" ).text()

            var total_burden = parseFloat(suta) + parseFloat(futa) +        // caett
                parseFloat(fica) + parseFloat(med) + parseFloat(caf) +
                parseFloat(wsc) + parseFloat(gl) + parseFloat(misc);

            if (selected_state == 'CA') {
                total_burden = parseFloat(total_burden) + parseFloat(caett);
            }

            $("#state-caett").attr('value', caett).prop("disabled", false).val(parseFloat(caett).toFixed(2));          // caett
            $("#state-suta").attr('value', suta).prop("disabled", false).val(parseFloat(suta).toFixed(2));
            $("#state-futa").attr('value', futa).val(parseFloat(futa).toFixed(2));       // .prop("disabled", false)
            $("#state-fica").attr('value', fica).val(parseFloat(fica).toFixed(2));       // .prop("disabled", false)
            $("#state-med").attr('value', med).val(parseFloat(med).toFixed(2));         // .prop("disabled", false)
            $("#state-caf").attr('value', caf).prop("disabled", false).val(parseFloat(caf).toFixed(2));
            $("#state-wsc").attr('value', wsc).prop("disabled", false).val(parseFloat(wsc).toFixed(2));
            $("#state-gl").attr('value', gl).prop("disabled", false).val(parseFloat(gl).toFixed(2));
            $("#state-miscellaneous").attr('value', misc).prop("disabled", false).val(parseFloat(misc).toFixed(2));

            $("#caett").attr('value', parseFloat(caett).toFixed(2));        // caett
            $("#suta").attr('value', parseFloat(suta).toFixed(2)).val(parseFloat(suta).toFixed(2));
            $("#futa").attr('value', parseFloat(futa).toFixed(2)).val(parseFloat(futa).toFixed(2));
            $("#fica").attr('value', parseFloat(fica).toFixed(2)).val(parseFloat(fica).toFixed(2));
            $("#med").attr('value', parseFloat(med).toFixed(2)).val(parseFloat(med).toFixed(2));
            $("#caf").attr('value', parseFloat(caf).toFixed(2)).val(parseFloat(caf).toFixed(2));
            $("#wsc").attr('value', parseFloat(wsc).toFixed(2)).val(parseFloat(wsc).toFixed(2));
            $("#gl").attr('value', parseFloat(gl).toFixed(2)).val(parseFloat(gl).toFixed(2));
            $("#misc").attr('value', parseFloat(misc).toFixed(2)).val(parseFloat(misc).toFixed(2));

            $("#total-burden").attr('value', parseFloat(total_burden).toFixed(2)).val(parseFloat(total_burden).toFixed(2));
            $("#state-total-burden").attr('value', parseFloat(total_burden).toFixed(2)).val(parseFloat(total_burden).toFixed(2));

            if (!$("#pdf-btn").hasClass('d-none'))
                $("#pdf-btn").addClass('d-none')
        },
        error: function (xhr, status, error) {
            if (!$("#pdf-btn").hasClass('d-none'))
                $("#pdf-btn").addClass('d-none')

            $("#state-caett, #state-suta, #state-caf, #state-wsc, #state-gl, #state-miscellaneous")        // caett          // #state-futa, #state-fica, #state-med,
                .prop("disabled", false);

            $("#selected-state").prop("disabled", false)
            $("#btn-calculate").prop("disabled", false)
        }
    })
}

// Calculator function
$("#work-site-var-form").submit(function (e) {
    e.preventDefault();
    $("#btn-calculate").prop("disabled", true)
    $("#cal-submit-loading").removeClass("d-none")
    $("#cal-submit").html("Calculating...")
    $("#gross-payroll-res, #revenue-res, #burden-res, #vms-msp-burden-res, #profit-res, #profit-pr-associate-res, " +
        "#profit-pr-associate-pr-hr-res, #gross-profit-margin-per-res, #gross-profit-margin-dol-res," +
        "#est-commission-cost-res, #time-clock-cost-res, #time-clock-pr-month-res, #bg-checks-res, #drug-test-res, " +
        "#safety-equipment-res, #onsite-cost-res, #onsite-equip-res, #ins-cost-res, #tech-cost-res, #misc-cost-res, " +
        "#pto-estimate-gross-burden-res, #misc-res, #transportation-res, #commission-res, #total-operating-expense-res, " +
        "#operating-income-res, #operating-income-sales-res").empty()

    $("#summary-res-table").addClass("d-none")
    if (!$("#pdf-btn").hasClass('d-none'))
        $("#pdf-btn").addClass('d-none')

    $("#errors").empty()

    $.ajax({
        type: "POST",
        url: "/calculate",
        headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
        data: $(this).serialize(),
        dataType: "json",
        encode: true,
        success: function (data) {
            $("#btn-calculate").prop("disabled", false)
            $("#cal-submit-loading").addClass("d-none")
            $("#cal-submit").html("Calculate")

            $("#gross-payroll-res").empty().append(formatResultCurrency(data.calculations.gross_payroll));
            $("#revenue-res").empty().append(formatResultCurrency(data.calculations.revenue));
            $("#burden-res").empty().append(formatResultCurrency(data.calculations.burden));
            $("#vms-msp-burden-res").empty().append(formatResultCurrency(data.calculations.vms_smp_burden));
            $("#profit-res").empty().append(formatResultCurrency(data.calculations.profit));
            $("#profit-pr-associate-res").empty().append(formatResultCurrency(data.calculations.profit_per_associate));
            $("#profit-pr-associate-pr-hr-res").empty().append(formatResultCurrency(data.calculations.profit_per_associate_per_hour));
            $("#gross-profit-margin-per-res").empty().append(parseFloat(data.calculations.gross_profit_margin_percent).toFixed(2) + "%");
            $("#gross-profit-margin-dol-res").empty().append(formatResultCurrency(data.calculations.gross_profit_margin));
            $("#est-commission-cost-res").empty().append(formatResultCurrency(data.calculations.estimated_commission_cost));
            $("#time-clock-cost-res").empty().append(formatResultCurrency(data.calculations.time_clock_initial_cost));
            $("#time-clock-pr-month-res").empty().append(formatResultCurrency(data.calculations.time_clock_cost_per_month));
            $("#bg-checks-res").empty().append(formatResultCurrency(data.calculations.background_checks));
            $("#drug-test-res").empty().append(formatResultCurrency(data.calculations.drug_tests));
            $("#safety-equipment-res").empty().append(formatResultCurrency(data.calculations.safety_equipment));
            $("#onsite-cost-res").empty().append(formatResultCurrency(data.calculations.onsite_or_additional_staff_cost));
            $("#onsite-equip-res").empty().append(formatResultCurrency(data.calculations.onsite_equip));
            $("#ins-cost-res").empty().append(formatResultCurrency(data.calculations.insurance_cost));
            $("#tech-cost-res").empty().append(formatResultCurrency(data.calculations.technology_cost));
            $("#misc-cost-res").empty().append(formatResultCurrency(data.calculations.misc_costs));
            $("#pto-estimate-gross-burden-res").empty().append(formatResultCurrency(data.calculations.pto_estimate));
            $("#misc-res").empty().append(formatResultCurrency(data.calculations.misc));
            $("#transportation-res").empty().append(formatResultCurrency(data.calculations.transportation));
            $("#commission-res").empty().append(formatResultCurrency(data.calculations.commission));
            $("#total-operating-expense-res").empty().append(
                formatResultCurrency(data.calculations.total_operating_expense)
            );
            $("#operating-income-res").empty().append(formatResultCurrency(data.calculations.operating_income));
            $("#operating-income-sales-res").empty().append(parseFloat(data.calculations.operating_income_percent_sales)
                .toFixed(2) + "%");
            $("#summary-res-table").removeClass("d-none")
            if ($("#pdf-btn").hasClass('d-none'))
                $("#pdf-btn").removeClass('d-none')

            if (data.p_length > 0) {
                $("#pdf-form").empty()
                $.each(data.payload, function (index, value) {
                    if (index != '_token') {
                        $("#pdf-form").append('<input name=' + index + ' type="hidden" id=' + '\"' + index + '_ajax' + '\"' + ' value=' + '\"' + value + '\"' + '>')
                    }

                });
            }
        },
        error: function (xhr, status, error) {
            $("#btn-calculate").prop("disabled", false)
            $("#cal-submit-loading").addClass("d-none")
            $("#cal-submit").html("Calculate")
            if (!$("#pdf-btn").hasClass('d-none'))
                $("#pdf-btn").addClass('d-none')

            if (xhr.responseJSON != "undefined") {
                console.log("xhr.responseJSON : " + xhr.responseJSON)
                $.each(xhr.responseJSON.errors, function (key, item) {
                    $("#errors").empty()
                    $("#errors").append(
                        "<div class='alert alert-danger alert-dismissible fade show mb-0 mt-2' role='alert'>"
                        + item + "<button type='button' class='close' data-dismiss='alert' aria-label='Close'>" +
                        "<span aria-hidden='true'>&times;</span></button></div>"
                    )
                });
            }
        }
    });
});

// currency formatting function
function formatResultCurrency(val) {
    let res = parseFloat(val, 10).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
        .toString()

    res = val < 0 ? '<div class="text-danger"><span class="float-left">$</span><span class="float-right">' +
        '(' + res.replace('-', '') + ')</span></div>' :
        '<span class="float-left">$</span><span class="float-right">' + res + '</span>';

    return res
}

// Loader on page load
$(document).ready(function () {
    var counter = 0;

    // Start the changing images
    setInterval(function () {
        changeImage(counter);
        counter++;
    }, 2500);

    // Set the percentage off
    loading();
});

function changeImage(counter) {
    var images = [
        '<i class="fa fa-dollar"></i>',
        '<i class="fa fa-calculator"></i>',
        '<i class="fa fa-fighter-jet"></i>',
        '<i class="fa fa-spinner"></i>',
    ];

    $(".preloadloader .image").html("" + images[counter] + "");
}

function loading() {
    var num = 0;
    for (i = 0; i <= 100; i++) {
        setTimeout(function () {
            $('.preloadloader span').html(num + '%');

            if (num == 100) {
                $(".preloadloader").fadeOut(2000, function () {
                    $(".calculator-content").fadeIn(1000);
                });
            }
            num++;
        }, i * 40);
    }
}

